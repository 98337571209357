import { ROUTES } from '~/data/routes';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useIsMobile } from './useIsMobile';
import posthog from 'posthog-js';

const useFooterCarPageAB = () => {
  const router = useRouter();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const isCarPage =
      router.pathname.includes(ROUTES.CAR_PAGE) ||
      router.pathname.includes(ROUTES.USED_CAR) ||
      router.pathname.includes(ROUTES.PRIVATE_LEASING);

    const isCarPageFooterV2 =
      posthog.getFeatureFlag('car-page-footer') === 'v2';

    return isCarPageFooterV2 && isCarPage && isMobile;
  }, [router.pathname, isMobile]);
};

export default useFooterCarPageAB;
